<template>
  <div class="page page-document">
    <iframe src="https://document.pub.jsxfedu.net"></iframe>
  </div>
</template>

<script>
export default {
  name: 'page-document'
}
</script>

<style lang="less" scoped>
iframe {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
